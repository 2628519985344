import styled from 'styled-components'

import {
  mobileVW,
  desktopVW,
  font,
  colors,
  letterSpacing,
  desktopBreakpoint,
  quad,
} from '../styles'

const BigHeading = styled.h3`
  font-size: ${mobileVW(32)};
  text-align: center;
  color: ${colors.white};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 1.2s ${quad.out};
  transition-delay: ${props => (props.visible ? 0 : 0)}s;
  transform: ${props =>
    props.visible ? 'scale(1) translateY(0%)' : 'scale(.9) translateY(0%)'};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(171)};
  }
`

export default BigHeading
