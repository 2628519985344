import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import BigHeading from '../components/BigHeading'
import { mobileVW, desktopVW, desktopBreakpoint } from '../styles'
import Subscribe from '../components/Subscribe'
import SEO from '../components/SEO'
import NewsThumbnail from '../components/NewsThumbnail'
import NewsWrapper from '../components/NewsWrapper'
import { useRecoilValue } from 'recoil'
import { startPageAnimation } from '../atoms'

const StyledNews = styled.section`
  margin: ${mobileVW(80)} ${mobileVW(40)} ${mobileVW(80)};
  text-align: center;

  ${desktopBreakpoint} {
    margin: ${desktopVW(250)} 0 ${desktopVW(200)};
  }
`

const Wrapper = styled.div`
  margin: ${desktopVW(200)} 0 ${desktopVW(200)};
`

const News = ({
  data: {
    contentfulNews: { nodes: newsItems },
    contentfulNewsPage: { title },
  },
}) => {
  const appear = useRecoilValue(startPageAnimation)
  return (
    <>
      <SEO title={`NEWSROOM | ASK PHILL VENTURES`} />
      <StyledNews>
        <BigHeading visible={appear}>{title}</BigHeading>
        <Wrapper>
          <NewsWrapper>
            {newsItems.map(({ title, link, image }) => (
              <NewsThumbnail title={title} link={link} image={image} />
            ))}
          </NewsWrapper>
        </Wrapper>
      </StyledNews>
    </>
  )
}

export const PageQuery = graphql`
  query News($id: String!) {
    contentfulNewsPage(id: { eq: $id }) {
      title
    }
    contentfulNews: allContentfulNews {
      nodes {
        title
        link
        image {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default News
