import styled from 'styled-components'

import {
  mobileVW,
  desktopVW,
  font,
  colors,
  letterSpacing,
  desktopBreakpoint,
} from '../styles'

const NewsWrapper = styled.div`
  display: block;
  margin-top: ${mobileVW(44)};

  ${desktopBreakpoint} {
    display: flex;
    justify-content: space-between;
    margin: ${desktopVW(60)} ${desktopVW(110)} ${desktopVW(100)};
  }
`

export default NewsWrapper
