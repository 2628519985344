import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  colors,
  mobileBreakpoint,
} from '../styles'

const StyledNewsThumbnail = styled.a`
  flex: 0 0 90%;
  display: flex;

  ${desktopBreakpoint} {
    display: block;
    flex: 0 0 28%;
    text-align: left;
    &:nth-child(2) {
      margin-top: ${desktopVW(60)};
    }

    &:hover {
      span {
        background-size: 100% 100%;
      }
    }
  }
`

const StyledImage = styled(Image)`
  ${mobileBreakpoint} {
    width: 30vw;
    margin: 0 5vw 10vw 0;
  }
`

const Title = styled.h3`
  position: relative;
  margin-top: ${mobileVW(17)};
  margin-bottom: ${mobileVW(40)};
  font-size: ${mobileVW(17)};
  line-height: ${mobileVW(26)};
  text-align: left;
  width: 60%;

  span {
    width: calc(100%);
    background-image: linear-gradient(transparent calc(100% - 1px), #fff 1px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 1s;
    text-align: left;
  }

  ${desktopBreakpoint} {
    margin-top: ${desktopVW(25)};
    margin-bottom: 0;
    width: 100%;
    text-align: left;
    font-size: ${desktopVW(25)};
    line-height: ${desktopVW(35)};
  }
`

const NewsThumbnail = ({ image, link, title }) => (
  <StyledNewsThumbnail href={link} target='_blank'>
    <StyledImage fluid={image.fluid} backgroundColor={colors.backgroundImage} />
    <Title>
      <span>{title}</span>
    </Title>
  </StyledNewsThumbnail>
)

export default NewsThumbnail
